/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import { Context } from '~context/MainProvider';
import { translate } from 'gatsby-core-theme/src/helpers/getters';

import LikeIcon from '../../../../components/atoms/icons/likeIcon.js';
import DislikeIcon from '../../../../components/atoms/icons/dislikeIcon.js';

import styles from './pros-cons.module.scss';
import keygen from '~helpers/keygen';

const ProsCons = ({ module = {}, pros = 'Pros', cons = 'Cons', icons }) => {
  const { translations } = useContext(Context) || {};
  return (
    <div className={styles.prosCons || ''}>
      <span className={styles.title}>
        {translate(translations, 'pros_cons_title', 'Pros & Cons')}
      </span>
      <div className={styles.prosConsBoxContainer}>
        <div className={styles.pros || ''}>
          <ul>
            {module.pros.map((perk) => (
              <li key={keygen()}>
                <span className={styles.listIcon || ''}>
                  <LikeIcon />
                </span>
                {perk}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.cons || ''}>
          <ul>
            {module.cons.map((con) => (
              <li key={keygen()}>
                <span className={styles.listIcon || ''}>
                  <DislikeIcon />
                </span>
                {con}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProsCons;
