import React from 'react';

const LikeIcon = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.69812 16C1.67143 15.9402 1.60701 15.954 1.56182 15.937C1.09916 15.7615 0.811253 15.3572 0.810898 14.865C0.810186 11.8379 0.810186 8.81072 0.810898 5.78321C0.810898 5.13621 1.30629 4.6426 1.954 4.64189C3.0245 4.64082 4.095 4.64473 5.16551 4.63797C5.31355 4.6369 5.35982 4.66217 5.35982 4.82481C5.35413 8.54986 5.35555 12.2746 5.35555 15.9996H1.69812V16Z"
      fill="white"
    />
    <path
      d="M15.5329 15.9017H9.10673C9.09854 15.8622 9.0658 15.8686 9.03804 15.8636C7.61201 15.6035 6.66678 14.3394 6.68564 12.9827C6.71269 11.0371 6.69489 9.09112 6.69134 7.14514C6.68991 6.39671 6.94045 5.74971 7.43834 5.19524C8.11416 4.4429 8.79035 3.69092 9.46688 2.93965C10.2779 2.03855 11.0915 1.13958 11.9001 0.236699C12.0894 0.0253038 12.3061 -0.0483644 12.5816 0.0313539C12.8072 0.0968367 13.0353 0.158049 13.266 0.201823C14.372 0.412151 15.0379 1.70473 14.3763 2.80477C13.9937 3.44038 13.6517 4.10054 13.2919 4.75003C13.2492 4.8269 13.2033 4.90235 13.1866 4.99168C13.114 5.37497 13.3756 5.67783 13.793 5.67889C14.8934 5.68174 15.9938 5.67498 17.0942 5.68138C17.8764 5.68601 18.4697 6.03976 18.8911 6.69317C19.0487 6.93766 19.1135 7.21596 19.19 7.48999V8.12881C19.1786 8.14019 19.1601 8.15051 19.1573 8.16368C18.7644 9.96695 18.3718 11.7702 17.9814 13.5738C17.7401 14.6885 17.075 15.4227 15.9977 15.7853C15.8468 15.8362 15.6881 15.8636 15.5333 15.902L15.5329 15.9017Z"
      fill="white"
    />
  </svg>
);

export default LikeIcon;
