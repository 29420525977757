import React from 'react';

const Dislike = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame" clip-path="url(#clip0_572_436)">
      <path
        id="Vector"
        d="M15.4658 -8.12492e-06H8.99988C8.99164 0.0397394 8.9587 0.0332939 8.93077 0.0383071C7.49591 0.300068 6.54484 1.57199 6.56382 2.93701C6.59103 4.89467 6.57313 6.85268 6.56955 8.8107C6.56811 9.56375 6.82021 10.2148 7.32117 10.7727C8.00117 11.5296 8.68154 12.2863 9.36226 13.0422C10.1783 13.9489 10.9969 14.8534 11.8105 15.7619C12.001 15.9746 12.2191 16.0487 12.4962 15.9685C12.7233 15.9026 12.9528 15.841 13.1848 15.797C14.2978 15.5853 14.9677 14.2848 14.3021 13.1779C13.9171 12.5384 13.573 11.8741 13.211 11.2206C13.168 11.1433 13.1218 11.0674 13.105 10.9775C13.0319 10.5918 13.2951 10.2871 13.7152 10.286C14.8224 10.2831 15.9296 10.29 17.0368 10.2835C17.8238 10.2789 18.4208 9.92291 18.8447 9.26547C19.0034 9.01946 19.0685 8.73944 19.1455 8.46371V7.82095C19.1341 7.80949 19.1154 7.79911 19.1126 7.78586C18.7173 5.97143 18.3223 4.15701 17.9295 2.34223C17.6867 1.22071 17.0174 0.481976 15.9335 0.117086C15.7817 0.0658797 15.622 0.0383071 15.4662 -0.000366211L15.4658 -8.12492e-06Z"
        fill="white"
      />
      <path
        id="Vector_2"
        d="M1.7479 0C1.72104 0.0601584 1.65623 0.0461931 1.61075 0.0633812C1.14524 0.240276 0.855546 0.646703 0.855188 1.14194C0.85483 4.18782 0.85483 7.2337 0.855188 10.2796C0.855188 10.9306 1.35364 11.4272 2.00536 11.428C3.08248 11.429 4.15961 11.4251 5.23673 11.4319C5.38569 11.433 5.43224 11.4075 5.43224 11.2439C5.42651 7.49581 5.42795 3.74809 5.42795 0H1.7479Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_572_436">
        <rect width="18.2903" height="16" fill="white" transform="translate(0.854858)" />
      </clipPath>
    </defs>
  </svg>
);

export default Dislike;
